<template>
  <div class="modal topup-modal">
    <div class="overlay" @click="$parent.closeTopUpModal"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$parent.closeTopUpModal" src="./../img/close.svg" />
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">
                  <b>Top up</b>
                </div>
                <label>
                  <div class="title small cur">{{ currency }}</div>
                  <div class="desc"></div>
                  <input type="text" @input="allowOnlyNumbers" placeholder="Enter amount" v-model="amount" />
                </label>
                <button 
                  :disabled="$parent.minOrderAmount && $parent.minOrderAmount > amount" 
                  @click="toCheckout" 
                  class="button"
                >Next</button>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopUpModal",
  props: ['currency'],
  components: {},
  data: function () {
    return {
      amount: ''
    };
  },
  mounted() {},
  methods: {
    allowOnlyNumbers(){
      this.amount = this.amount.replace(/[^0-9.,]/g, '');
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }
      this.amount = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    toCheckout() {
      this.$emit('setTopUpAmount', this.amount);
    },
  },
};
</script>
