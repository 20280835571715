<template>
  <main class="main">
    <div class="hero-section-container">
      <img src="../img/hero.png" class="bg" />
      <img src="../img/forest.svg" class="forest"/>
      <img src="../img/chubriki.png" :class="['chubriki', {'active': $parent.heroImagesLoaded}]"/>
      <img src="../img/cloud1.png" :class="['cloud1', {'active': $parent.heroImagesLoaded}]"/>
      <img src="../img/cloud2.png" :class="['cloud2', {'active': $parent.heroImagesLoaded}]"/>
      <img src="../img/cloud3.png" :class="['cloud3', {'active': $parent.heroImagesLoaded}]"/>
      <div class="section hero-section">
        <div class="wrapper">
          <div :class="['title', {'active': $parent.heroImagesLoaded}]">
            <b>welcome to</b>
          </div>
          <img src="../img/vaulthex.svg"  :class="['vaulthex', {'active': $parent.heroImagesLoaded}]"/>
        </div>
      </div>
    </div>
    <div class="section join-section">
      <div class="wrapper">
        <img src="../img/joinCloud1.png" class="cloud1" />
        <img src="../img/joinCloud2.png" class="cloud2" />
        <img src="../img/drone.png" class="drone" />
        <div class="title big">
          <b>Join vaulthex</b>
        </div>
        <div class="desc big">
          Your ultimate hub for all things Rust! Discover expert guides, connect with our vibrant community, and shop exclusive in-game items and merchandise. Dive in and conquer the world of Rust with us!
        </div>
        <div class="ip-container">
          <div class="title">
           <b> {{ $parent.ip }}</b>
          </div>
          <button class="button black" v-clipboard="$parent.ip" v-clipboard:success="clipboardSuccessHandler">
            <img src="../img/copy.svg" class="img"/>
            <span>{{copied ? 'Copied' : 'Copy IP' }}</span>
          </button>
        </div>
      </div>
      <div class="medals">
        <div class="item"> 
          <img src="../img/medal1.svg" class="img" />
          <div class="title">
            <b>Custom</b>
          </div>
          <div class="desc">maps and missions</div>
        </div>
        <div class="item"> 
          <img src="../img/medal2.svg" class="img" />
          <div class="title">
            <b>Special</b>
          </div>
          <div class="desc">events and tournaments</div>
        </div>
        <div class="item"> 
          <img src="../img/medal3.svg" class="img" />
          <div class="title">
            <b>Unique</b>
          </div>
          <div class="desc">progression and reward systems</div>
        </div>
      </div>
    </div>
    <div class="section how-section" id="how" ref="targetSection">
      <div class="wrapper">
        <div class="left" ref="movableElement">
          <img src="../img/howCloud.png" class="howCloud" />
          <img src="../img/bycicleMan.png" class="bycicleMan" />
        </div>
        <div class="right" ref="movableElement2">
          <div class="title">
            <b>HOW IT WORKS</b>
          </div>
          <div class="list">
            <div class="item">
              <div class="title medium">
                <b>1.</b>
              </div>
              <div class="desc">
                Installing a legitimate copy of Rust is essential for accessing our gaming server
              </div>
            </div>
            <div class="item">
              <div class="title medium">
                <b>2.</b>
              </div>
              <div class="desc">
                Copy IP {{ $parent.ip }}
              </div>
            </div>
            <div class="item">
              <div class="title medium">
                <b>3.</b>
              </div>
              <div class="desc">
                That's it! Start playing now, and if you'd like to buy in-game items, be sure to check out our store.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section faq-section" ref="faqSection">
      <img src="./../img/cloudFaq1.png" class="cloud1" ref="cloud1"/>
      <img src="./../img/cloudFaq2.png" class="cloud2" ref="cloud2"/>
      <div class="wrapper" id="faq">
        <div class="title big">
          <b>FAQ</b>
        </div>
        <div class="accordion">
          <div 
            :class="['item', {'active': isActivePanel(index)}]"
            v-for="(item, index) in $parent.faq" :key="index"
          >
            <div class="panel" @click="togglePanel(index)">
              <img src="./../img/faq-dropdown.svg" class="arrow"/>
              <div class="title medium">
                <b>{{ item.question }}</b>
              </div>
            </div>
            <div class="desc" v-if="isActivePanel(index)">
              {{ item.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section rules-section">
      <div class="wrapper">
        <img src="../img/rulesCloud1.png" class="cloud1" />
        <div class="left">
          <div class="title big">
            <b>Brief Rules:</b>
          </div>
          <div class="list">
            <div class="item">
              <div class="title medium">
                <b>1.</b>
              </div>
              <div class="desc">
                Treat all members with respect and kindness.
              </div>
            </div>
            <div class="item">
              <div class="title medium">
                <b>2.</b>
              </div>
              <div class="desc">
                Do not share, promote, or use cheats, hacks, or exploits.
              </div>
            </div>
            <div class="item">
              <div class="title medium">
                <b>3.</b>
              </div>
              <div class="desc">
                Do not share personal information about yourself or others.
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="../img/manWithFlag.png" class="img" />
          <img src="../img/rulesCloud2.png" class="cloud2" />
        </div>
      </div>
    </div>
    <div class="join2-section">
      <img src="../img/bgForest.png" class="bg" />
      <img src="../img/forest.svg" class="forest"/>
      <img src="../img/join.svg" class="join-img" />
      <div class="wrapper">
          <div class="ip-container">
            <div class="title">
            <b> {{ $parent.ip }}</b>
            </div>
            <button class="button black" v-clipboard="$parent.ip" v-clipboard:success="clipboardSuccessHandler">
              <img src="../img/copy.svg" class="img"/>
              <span>{{copied ? 'Copied' : 'Copy IP' }}</span>
            </button>
          </div>
          <div class="desc big">
            Join vaulthex ! Connect with players, join events, and get the latest Rust updates. Follow us, subscribe, and explore our guides. Let's conquer Rust together!
          </div>
      </div>
    </div>
  </main>
</template>
<script>
import Vue from 'vue'
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

export default {
  name: 'Home',
  props: ['currency', 'currencyCode'],
  components: {
  },
  data: function() {
    return {
      activePanels: [],
      copied: false,
      isMoving: false,
      startPosition: 0,
      isMoving2: false,
      startPosition2: 0,

    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const section = this.$refs.targetSection;
      const movableElement = this.$refs.movableElement;
      const movableElement2 = this.$refs.movableElement2;

      if (section && movableElement && movableElement2) {
        const rect = section.getBoundingClientRect();

        // Check if the section's top is at the top of the viewport
        if (rect.top <= 200 && rect.bottom > 0 && !this.isMoving) {
          this.isMoving = true;
          this.startPosition = window.scrollY;
        }

        // Move the element if the section is in view
        if (this.isMoving) {
          const scrollOffset = window.scrollY - this.startPosition;
          movableElement.style.transform = `translateX(-${scrollOffset}px) translateY(${scrollOffset}px)`;
          movableElement2.style.transform = `translateX(+${scrollOffset}px) translateY(${scrollOffset}px)`;
        }
      }


      const faqSection = this.$refs.faqSection;
      const cloud1 = this.$refs.cloud1;
      const cloud2 = this.$refs.cloud2;

      if (faqSection && cloud1 && cloud2) {
        const rect2 = faqSection.getBoundingClientRect();

        if (rect2.top <= 400 && rect2.bottom > 0 && !this.isMoving2) {
          this.isMoving2 = true;
          this.startPosition2 = window.scrollY;
        }

        // Move the element if the section is in view
        if (this.isMoving2) {
          const scrollOffset = window.scrollY - this.startPosition2;
          cloud1.style.transform = `translateX(calc(-50% - (-${scrollOffset}px + 1260px))) translateY(calc(-50% - 90px))`;
          cloud2.style.transform = `translateX(calc(-50% + (-${scrollOffset}px + 1260px))) translateY(calc(-50% - 30px))`;
        }
      }


    },
    clipboardSuccessHandler() {
      let self = this;
      self.copied = true;
      setTimeout(() => {
        self.copied = false;
      }, 1200);
    },
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>