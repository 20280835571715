<template>
	<div class="menu">
      <div class="wrapper">
		<div class="container">
			<ul class="nav">
				<li class="nav__item">
					<router-link to="/" class="title extrasmall">
						HOME
					</router-link>
				</li>
				<li class="nav__item">
					<div class="title extrasmall" @click="scrollToSection('#faq')">
					FAQ
					</div>
				</li>
				<li class="nav__item">
					<router-link to="/products" class="title extrasmall">
					SHOP
					</router-link>
				</li>
				<li class="nav__item">
					<div class="title extrasmall" @click="scrollToSection('#how')">
					HOW IT WORKS
					</div>
				</li>
			</ul>
			<div class="balance" v-if="$parent.isAuth">
				<button class="button profile-button dark" @click="$parent.openTopUpModal">
					<div class="balance-text">
					<img src="../img/wallet.svg" class="img"/>
					<span class="title small amount">{{Math.floor($parent.profileData.balance * 100) / 100}} {{$parent.currency}}</span>
					</div>
				</button>
			</div>
			<router-link to="/profile" class="desc name-desc" v-if="$parent.isAuth">
				<b>{{ $parent.profileData.name }}</b>
				</router-link>
			<div class="button black button-login" v-if="!$parent.isAuth" @click="$parent.openSignInModal">
				<img src="../img/steam.png" class="img" />
				LOGIN WITH STEAM
			</div>
			<div class="select-container" v-if="curr && ($parent.currencyOptions.length > 1)">
				<select class="select currency-select" v-model="curr"
					@change="selectCurr()"
				>
					<option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
				</select>
			</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
	name: 'Menu',
	props: ['currency', 'currencyCode', 'currencyValue'],
	data: function() {
		return {
			topUpIsVisible: false,
			amount: 5,
			curr: ''
		}
	},
	computed: {
		currOptions() {
			return this.$parent.currencyOptions.map(({code}) => code);
		}
	},
	watch: {
		currencyCode: function (newValue) {
			this.curr = newValue;
		}
	},
	mounted() {
		this.curr = this.currencyCode;
	},
	methods: {
		selectCurr() {
			this.changeCurrency(this.curr)
			localStorage.setItem("currency", this.curr);
		},
		changeCurrency(item) {
			this.$emit('changeCurrency', item)
		},
		scrollToSection(section) {
			this.$parent.closeMenu();
			this.$emit('scrollToSection', section);
		},
		decrease() { 
			if (this.amount > 0) {
				this.amount = this.amount - 1
			} else {
				this.amount = 0
			}
		},
		increase() {
			this.amount = this.amount + 1
		},
		toCheckout() {
			if (this.amount > 0) {
				this.topUpIsVisible = false;
				this.$emit('setAmount', this.amount);
				this.$router.push({ path: 'cart', query: { amount: this.amount }})
			}
		}
	},
}
</script>
