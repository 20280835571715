<template>
  <div class="modal sign-modal">
    <div class="overlay" @click="$emit('closeSignInModal')"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$emit('closeSignInModal')" src="./../img/close.svg" />
            <div :class="['form-fields login-content', { hidden: !loginContentVisible }]">
              <div class="form-fields-wrapper">
                <div class="title small">Login</div>
                <label>
                  <div class="desc">E-mail:</div>
                  <input type="email" :placeholder="'Email'" v-model="email" />
                </label>
                <label>
                  <div class="desc">Password:</div>
                  <input type="password" :placeholder="'Password'" v-model="pass" />
                </label>
                <div class="cta-container">
                  <button class="button" @click="submitLogin">
                    <span>Log In</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInModal",
  props: {
    isVerified: Boolean
  },
  components: {},
  data: function () {
    return {
      loginContentVisible: true,
      email: "",
      pass: "",
    };
  },
  mounted() {},
  methods: {
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(() => {
        this.loginContentVisible = true;
      }, 100);
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass,
      };
      this.$emit("login2", loginData);
    },
  },
};
</script>
