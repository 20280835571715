<template>
  <div class="modal order-modal">
    <div class="overlay" @click="$parent.closeOrderModal"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$parent.closeOrderModal" src="./../img/close.svg" />
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="modal-top">
                  <div class="title medium">
                    <b>Enter contact information</b>
                  </div>
                </div>
                <div class="fields">
                  <label>
                    <span class="desc">First name:</span>
                    <input type="text" v-model="name" placeholder="Your first name"/>
                  </label>
                  <label>
                    <span class="desc">Last name:</span>
                    <input type="text" v-model="surname" placeholder="Your last name"/>
                  </label>
                  <label>
                    <span class="desc">Phone:</span>
                    <input type="text" v-model="phone" @input="allowOnlyNumbers" placeholder="Your phone number"/>
                  </label>
                  <label>
                    <span class="desc">Email:</span>
                    <input type="email" v-model="email" placeholder="Your email"/>
                  </label>
                  <label>
                    <span class="desc">Address:</span>
                    <input type="text" v-model="address" placeholder="Your address"/>
                  </label>
                  <label>
                    <span class="desc">City:</span>
                    <input type="text" v-model="city" placeholder="Your city"/>
                  </label>
                  <label>
                    <span class="desc">Country:</span>
                    <select v-model="country">
                      <option value="" disabled selected>Your county</option>
                      <option v-for="item in countryOptions" :value="item.id" :key="item.title">{{item.title}}</option>
                    </select>
                  </label>
                  <label>
                    <span class="desc">ZIP Code:</span>
                    <input type="text" v-model="postCode" placeholder="Your zip code"/>
                  </label>
                </div>
                <div class="modal-bottom">
                  <div class="modal-bottom-left">
                    <div class="chekbox-container">
                      <label class="chekbox-label">
                        <div class="chekbox-label-wrapper">
                          <input 
                            v-model="terms"
                            type="checkbox" 
                            name="terms" 
                          >
                          <div class="checkbox" />
                          <span class="desc">I agree with </span>
                          <a 
                            class="desc orange"
                            @click="$parent.goToPage('privacy')"
                          > 
                            privacy policy
                          </a>
                          <span class="desc"> and </span>
                          <a 
                            class="desc orange"
                            @click="$parent.goToPage('terms')"
                          > 
                            terms and conditions
                          </a>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="modal-bottom-right">
                    <div class="text">
                      <div class="title small">
                        <b>TOP UP total:</b>
                      </div>
                      <div class="title medium">
                        <b>
                          {{ $parent.topUpAmount }}
                        </b>
                        <b>
                          {{ currency }}
                        </b>
                      </div>
                    </div>
                    <div :class="['button', {'disabled': !requiredFieldsAreFilled}]" @click="submit">TOP UP</div>
                  </div>
                </div>
                <transition name="fade">
                  <div class="desc error" v-if="$parent.error">{{$parent.error}}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderModal",
  props: ['currency'],
  components: {},
  data: function () {
    return {
      amount: 5,
      countryOptions: [],
      name: '',
      surname: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      country: '',
      postCode: '',
      terms: ''
    };
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.country && 
        this.city && this.address  && this.terms
        && this.postCode 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  mounted() {
    this.getProfile();
    this.getCounties();
  },
  methods: {
    allowOnlyNumbers(){
      this.phone = this.phone.replace(/[^0-9.,]/g, '');
      const parts = this.phone.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }
      this.phone = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    submit() {
      let amount = this.$parent.topUpAmount;
      let data = {
        "first_name": this.name,
        "last_name": this.surname,
        "phone": this.phone,
        "email": this.email,
        "country_id": this.country,
        "city": this.city,
        "address": this.address,
        "zip": this.postCode,
        "amount": amount
      }
      this.$emit('topUp', data)
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
         
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    toCheckout() {
      this.$parent.closeTopUpModal();
    },
  },
};
</script>
