<template>
  <main class="main page-inside products-page">
    <div class="main-wrapper">
      <div class="section shop-section">
        <img src="../img/bgimg.png" class="bg"/>
        <div class="wrapper">
          <div class="filters">
            <img src="../img/sawManCloud.png" class="sawManCloud"/>
            <img src="../img/sawMan.png" class="sawMan"/>
            <div class="search">
              <input type="text" placeholder="Search" v-model="searchedProductText" @keyup.enter="searchSubmit"/>
              <div class="button black" @click="searchSubmit">
                <img src="../img/glass.svg" class="img"/>
              </div>
            </div>
            <div class="sort">
              <div class="select-container" v-if="sortOptions.length">
                <Select2 class="select2 single" v-model="sort" 
                  :options="sortOptions" 
                  placeholder="SORT BY CHEAPEST"
                  @select="selectSort($event)"
                />
              </div>
            </div>
            <div class="filters-item" v-if="categories && categories.length">
              <div class="desc small gray">
                <b>Type:</b>
              </div>
              <div class="select-container">
                <label class="chekbox-label" v-for="(item, i) in categories" :key="i">
                  <div class="chekbox-label-wrapper">
                    <input type="checkbox" v-model="activeCategory" :value="item" @input="checkActiveCategory" />
                    <div class="checkbox"></div>
                    <span class="desc">
                      {{ item.text }}
                    </span>
                  </div>
                </label>
              </div>
            </div>
            <div class="reset-button button black" @click="reset()">
              <span>RESET ALL FILTERS</span>
              <img src="../img/reset.svg" class="reset"/>
            </div>
          </div>
          <transition name="fade">
            <div class="product-list" v-if="productList.data && productList.data.length">
              <div class="item" v-for="item in productList.data" :key="item.id" @click="openProductModal(item)">
                <img :src="imgDomain + item.img + '.png'" class="img"/>
                <!-- <div class="available desc">Available on server</div> -->
                <!-- <div class="available desc not">Not available on server</div> -->
                <div class="bottom">
                  <span class="name desc">{{item.name}}</span>
                  <div class="price-container">
                    <span class="price">
                      <span class="title medium amount">{{item.price.toFixed(2)}}</span>
                      <span class="title medium currancy">{{$parent.currency}}</span>
                    </span>
                    <div class="price old-price">
                      <span class="title medium"> {{$parent.currency}} {{item.old_price.toFixed(2)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="title no-products" v-if="productList.data && !productList.data.length">
              <b>No products</b>
            </div>
          </transition>
          <pagination v-if="totalProducts" v-model="activePage" :per-page="20" :records="totalProducts" @paginate="changePage"/>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from 'vue-pagination-2';
import Select2 from 'vue3-select2-component';

export default {
  name: 'Products',
  props: ['currency', 'currencyCode'],
  components: {
    Pagination,
    Select2
  },
  data: function() {
    return {
      imgDomain: '',
      productList: [],
      activePage: 1,
      totalProducts: null,
      searchedProductText: '',
      activeCategory: [{ id: 0, text: 'All' }],
      sort: 'price',
      categories: [],
      sortOptions: [
        {
          id: 'price',
          text:'SORT BY CHEAPEST'
        }, 
        {
          id: '-price',
          text: 'SORT BY EXPENSIVE'
        }
      ],
    }
  },
  watch: {
    currencyCode: function () {
      this.filterProducts();
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getCategories();
    this.$http.get(process.env.VUE_APP_API + 'items')
    .then((res) => {
      this.productList = res.data;
      this.totalProducts = res.data.meta.total;
      this.filterProducts();
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignInModal();
      }
    })
  },
  methods: {
    checkActiveCategory() {
      setTimeout(() => {
        let newVal = this.activeCategory;
        console.log(newVal);
        if (newVal.length > 1 && newVal[0].text === 'All') {
          console.log(1)
          let newArr = newVal.filter(obj => obj.text !== 'All');
          this.activeCategory = newArr;
        } else if (newVal.length > 1 && newVal[0].text !== 'All' && newVal.some(obj => obj.text === 'All')) {
          console.log(2)
          let newArr = newVal.filter(obj => obj.text === 'All');
          this.activeCategory = newArr;
        } else if (newVal.length == 1 && newVal[0].text === 'All') {
          console.log(3)
          this.activeCategory = [{ id: 0, text: 'All' }];
        }
        this.filterProducts();
      }, 300);
    },
    reset() {
      this.activeCategory = [{ id: 0, text: 'All' }];
      this.searchedProductText = '';
      this.filterProducts();
      window.scrollTo(0, 0);
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload.map(({ id, name, ...rest }) => ({
          id,
          text: name,
          ...rest,
        }));
        this.categories.unshift({ id: 0, text: 'All' });
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    selectSort(event) {
      this.sort = event.id;
      this.filterProducts();
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    searchSubmit() {
      this.filterProducts();
    },
    filterProducts() {
      let addSort = 'sort=' + this.sort
      let addPage = '&page=' + this.activePage
      let addSearch = '&filter[name]=' + this.searchedProductText
      let addcCategory;
      if (this.activeCategory.length == 1 && this.activeCategory[0].id == 0 || this.activeCategory == '') {
       addcCategory = '';
      } else {
        let newArr = this.activeCategory.map(item => item.id);

        addcCategory = '&filter[category]=' + newArr
      }
      this.$http.get(process.env.VUE_APP_API + 'items?' + addSort + addPage + addSearch + addcCategory + '&per_page=20')
      .then((res) => {
        this.productList = res.data
        this.totalProducts = res.data.meta.total
      })
      .catch(() => {
        
      })
    },
    openProductModal(product) {
      this.$emit('openProductModal', product)
    }
  }
}
</script>