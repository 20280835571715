<template>
  <main class="main page-inside">
    <div class="profile">
      <div class="section profile-section">
        <img src="../img/bgimg.png" class="bg"/>
        <div class="wrapper">
          <div class="profile-content">
            <div class="profile-info">
              <div class="avatar">
                <img :src="$parent.profileData.avatar" class="img"/>
              </div>
              <div class="text">
                <div class="row">
                  <div class="desc">
                    <b>Name:</b>
                  </div>
                  <div class="desc">{{$parent.profileData.name}}</div>
                </div>
                <div class="row">
                  <div class="desc">
                    <b>ID:</b>
                  </div>
                  <div class="desc">{{$parent.profileData.steam_id}}</div>
                </div>
              </div>
              <div class="balance-block">
                <div class="balance-block-wrapper">
                  <div class="title small">
                    <b>BALANCE:</b>
                  </div>
                  <div class="title amount">
                    <b>{{$parent.profileData.balance}} </b>
                    <span class="currency">
                      <b>{{$parent.currency}}</b>
                    </span>
                  </div>
                  <div class="button black" @click="$parent.openTopUpModal">
                    <img src="../img/plus.svg" class="img"/>
                  </div>
                </div>
              </div>
              <button class="button black" @click="$parent.logout">
                <span>LOG OUT</span>
              </button>
            </div>
            <div class="tables">
              <div class="title medium tables-title">
                <b>History</b>
              </div>
              <div class="history-info">
                <div 
                  :class="['title medium', {'active': activeTab == 0}]"
                  @click="activeTab = 0"
                >
                  Items
                </div>
                <div 
                  :class="['title medium', {'active': activeTab == 1}]"
                  @click="activeTab = 1"
                >
                  Top Up
                </div>
                <div class="table-container" v-if="activeTab == 0 && $parent.orderHistory.length">
                  <table>
                    <thead>
                      <tr>
                        <td>Date</td>
                        <td>Item</td>
                        <td>Count</td>
                        <td>Total price</td>
                        <td>Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                        <td>{{$parent.formatDate(item.date)}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.count}}</td>
                        <td>
                          <b>{{item.total_price}} {{item.currency}}</b>
                        </td>
                        <td :class="'status ' + item.status">
                          <span>{{item.status}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-container" v-if="activeTab == 1 && $parent.depositHistory && $parent.depositHistory.length">
                  <table>
                    <thead>
                      <tr>
                        <td>Date</td>
                        <td>Amount</td>
                        <td>Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in $parent.depositHistory" :key="i">
                        <td>{{$parent.formatDate(item.date)}}</td>
                        <td>{{item.amount}}  {{item.currency}}</td>
                        <td :class="'status ' + item.status">
                          <span>{{item.status}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: [],
  components: {
  },
  data: function() {
    return {
      activeTab: 0
    }
  },
  mounted() {
   
  },
  methods: {
    
  }
}
</script>