<template>
  <main class="main">
    <div class="first-section-container">
      <img src="../img/bgimg.png" class="bg"/>
      <div class="section first-section">
        <div class="wrapper">
          <div class="title">Contact Us</div>
          <div class="form">
            <label>
              <span class="title-tiny">FULL NAME:</span>
              <input type="text" v-model="name" placeholder="Your name"/>
            </label>
            <label>
              <span class="title-tiny">EMAIL:</span>
              <input type="email" v-model="email" placeholder="Your email"/>
            </label>
            <label>
              <span class="title-tiny">MESSAGE:</span>
              <textarea type="text" v-model="message" placeholder="Your message"></textarea>
            </label>
            <div class="button" @click="submit">Send</div>
            <transition name="fade">
              <div v-if="error" class="desc error">{{error}}</div>
            </transition>
            <transition name="fade">
              <div v-if="success" class="desc success">{{success}}</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'ContactUs',
  props: [],
  components: {
  },
  data: function() {
    return {
      name: '',
      email: '',
      message: '',
      error: '',
      success: ''
    }
  },
  methods: {
    submit() {
      let data = {
        name: this.name,
        email: this.email,
        message: this.message,
      }
      this.$http.post(process.env.VUE_APP_API + 'contact-us', data)
      .then((res) => {
        console.log(res);
        if (res.data.status == "OK") {
          let self = this;
          this.success = 'Thank you for your message!';
          setTimeout(function() {
            self.success = '';
          }, 1500)
        }
        if (res.data.status === 'ERROR'){
          let self = this;
          this.error = res.response.data.message
          setTimeout(function() {
            self.error = '';
          }, 1500)
        }
      })
      .catch((res) => {
        console.log(res);
        this.error = res.response.data.message
      })
    }
  }
}
</script>